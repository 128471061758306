import { defineStore } from 'pinia';

export const useGlobalFooterMenuStore = defineStore('footerMenu', () => {
  const menu = ref([]);
  let fetch: Promise<{ data: any }>;

  async function getFooterMenu() {
    if (menu.value.length) {
      return menu.value;
    }

    if (fetch === undefined) {
      fetch = useFetch(`/ecom-api/editorial/get-footer-menu`, {
        method: 'POST',
        body: JSON.stringify({
          regionCode: useRegionCode(),
        }),
      });
    }
    const { data: menuResponse } = await fetch;
    menu.value = menuResponse?.value?.menu || [];
    return menu.value;
  }

  function hasFooterMenu() {
    return menu.value.length > 0;
  }

  return {
    menu,
    getFooterMenu,
    hasFooterMenu,
  };
});
