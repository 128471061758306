import { defineStore } from 'pinia';
import { useAuthStore } from './auth';

export enum PetFormStep {
  Start = 'start',
  Type = 'type',
  Name = 'name',
  Birthdate = 'birthdate',
  AddMore = 'add more',
}

export const usePetStore = defineStore('pet', () => {
  const authStore = useAuthStore();
  const nuxtApp = useNuxtApp();

  const petTypes = ref<PetType[]>([]);
  const pets = ref<Pet[]>([]);
  const sharedPets = ref<Pet[]>([]);
  const shouldShowAddPetsForm = ref(true);

  const userHasPets = computed(
    () => pets.value.length > 0 || sharedPets.value.length > 0
  );

  async function getPetTypes() {
    try {
      const headers = authStore.getHeaders();
      const res = await nuxtApp.$apiFetch('/rest/pets/types', {
        headers,
        credentials: 'include',
      });

      petTypes.value = res;

      return petTypes.value;
    } catch (err) {
      console.error(err);
    }

    return false;
  }

  async function createPet(pet: Pet): Promise<boolean> {
    try {
      const headers = authStore.getHeaders();
      await nuxtApp.$apiFetch('/rest/pets', {
        headers,
        credentials: 'include',
        method: 'POST',
        body: pet,
      });

      return true;
    } catch (error) {
      nuxtApp.$sentryCaptureException(
        new Error(`Could not create pet: ${error}`)
      );

      return false;
    }
  }

  async function getUsersPets() {
    try {
      const headers = authStore.getHeaders();
      const res = await nuxtApp.$apiFetch('/rest/pets', {
        headers,
        credentials: 'include',
      });

      pets.value = res.animals;
      sharedPets.value = res.sharedAnimals;
    } catch (err) {
      console.error(err);
    }
  }

  return {
    getPetTypes,
    petTypes,
    createPet,
    pets,
    sharedPets,
    getUsersPets,
    userHasPets,
    shouldShowAddPetsForm,
  };
});
