<template>
  <div :class="['default-layout', hydrated ? 'hydrated' : 'hydrating']">
    <ClearCacheMeta
      v-if="
        brandsStore.error ||
        navMenuStore.error ||
        enabledFeaturesStore.hasFeatureQuery
      "
    />
    <Body>
      <a href="#main-content" class="sr-only">Skip to main content</a>
      <Header />
      <Sidebar v-if="hasShop" />
      <div
        v-if="showAddNameForm || showLoginLanding || showAddPetsForm"
        class="min-h-[500px]"
      >
        <AccountAddNameForm
          v-if="showAddNameForm"
          v-click-outside="hideAddNameForm"
          @success="
            (((showLoginLanding = true), (showAddNameForm = false)),
            authStore.tryLogin())
          "
        />
        <AccountLoginLanding
          v-if="showLoginLanding"
          @show-login-landing="showLoginLanding = $event"
          @show-add-pets-form="
            ((showAddPetsForm = $event), (showLoginLanding = false))
          "
        />
        <AccountAddPetsForm
          v-if="showAddPetsForm"
          @success="showAddPetsForm = false"
          @show-login-landing="
            ((showLoginLanding = $event), (showAddPetsForm = false))
          "
        />
      </div>

      <main
        v-show="!showLoginLanding && !showAddNameForm && !showAddPetsForm"
        id="main-content"
        :class="classNames"
      >
        <slot />
      </main>
      <Footer />

      <LazyBrazeStickyButton
        v-if="enabledFeaturesStore.isFeatureEnabled('brazeButton')"
      />
      <DevBar />
    </Body>
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { usePetStore } from '@/stores/pet';
import { useNavMenuStore } from '~/stores/navMenu';
import { useCartStore } from '~/stores/cart';
import { useBrandsStore } from '~/stores/brands';
import { useAuthStore } from '~/stores/auth';
import { useEnabledFeaturesStore } from '~/stores/enabledFeatures';

const AccountLoginLanding = defineAsyncComponent(
  () => import('~/components/domain/account/AccountLoginLanding.vue')
);

const route = useRoute();

const navMenuStore = useNavMenuStore();
const brandsStore = useBrandsStore();
const cartStore = useCartStore();
const authStore = useAuthStore();
const { profile, isLoggedIn } = storeToRefs(authStore);
const petStore = usePetStore();
const { userHasPets } = storeToRefs(petStore);
const enabledFeaturesStore = useEnabledFeaturesStore();

const hydrated = ref(false);

const { authBaseUrl } = useRuntimeConfig().public;
const { isAccountsEnabled } = useRuntimeConfig().public.featureFlags;

const showAddNameForm = ref(false);
const showAddPetsForm = ref(false);

const hideAddNameForm = () => {
  showAddNameForm.value = false;
};

const showLoginLanding = ref(false);
const hasShop = computed(() => useBaseCountryConfig().hasShop);

watch(
  () => route.query.is_sign_up,
  (query) => {
    if (isAccountsEnabled && query && profile.value?.firstName)
      showLoginLanding.value = !!route.query?.is_sign_up;
  },
  { immediate: true }
);

useHead({
  bodyAttrs: {
    class: 'bg-background-extra-light md:bg-background-light',
  },
});

const props = defineProps({
  classNames: {
    type: String,
    default: 'mx-auto mt-4 max-w-6xl px-4 md:mt-6 md:px-6',
  },
});

const { classNames } = toRefs(props);

if (isAccountsEnabled) {
  await authStore.tryLogin();
}

onMounted(async () => {
  if (isAccountsEnabled) {
    const referrer = document && document?.referrer ? document.referrer : null;

    const pageAccessedByReload = window.performance
      .getEntriesByType('navigation')
      .map((nav) => (nav as PerformanceNavigationTiming)?.type)
      .includes('reload');

    const wasRedirectedFromAuthClient =
      referrer &&
      authBaseUrl &&
      referrer.includes(authBaseUrl) &&
      !pageAccessedByReload; // Referrer is kept after refreshing in Safari, which would make use enter this if statement when refreshing after logout

    // If user comes from login and does not have a first name, show add name form, or if user has no pets, show login landing page
    if (wasRedirectedFromAuthClient) {
      if (!profile.value?.firstName) {
        const idsAuthToken = useIdsAuthTokenCookie();
        showAddNameForm.value = !!idsAuthToken.value;

        // If user went to unified login and clicks back arrow there (ie. does not login), send the user back to the login page (but not if they are already on the login page)
        if (
          !idsAuthToken.value &&
          route.name !== 'login' &&
          authStore.shouldRedirectToLogin === null
        ) {
          authStore.shouldRedirectToLogin = true;
        }
        if (authStore.shouldRedirectToLogin) {
          authStore.shouldRedirectToLogin = false;
          navigateTo(
            useLocalePath()({
              name: 'shop-login',
            })
          );
        }
      }
      if (
        !userHasPets.value &&
        isLoggedIn.value &&
        petStore.shouldShowAddPetsForm
      ) {
        // Make the fetch here to avoid fetching it on every page load and only when the user is actually logging in
        await petStore.getUsersPets();
        // If user still has not pets after fetching them, show login landing page
        if (!userHasPets.value) showLoginLanding.value = true;
      }
    } else {
      showAddNameForm.value = false;
    }
  }

  nextTick(() => {
    hydrated.value = true;
  });
  const routeName = route?.name?.toString();
  if (
    !routeName?.includes('receipt') &&
    !routeName?.includes('recommended-products')
  ) {
    // Do not refetch cart on receipt page since this causes the finished cart to be restored in localstorage
    // Skip fetching on recommended products page since we want to be able to await it there

    await cartStore.getCart();
  }
});
</script>
