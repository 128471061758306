<template>
  <div
    ref="navMenuDropdown"
    class="transform-all absolute left-0 top-[calc(100%)] z-40 max-h-[calc(100vh-91px)] w-full origin-top overflow-auto border-t bg-background-extra-light duration-200"
    :class="
      isNavMenuOpen && hasVisibleChildren
        ? 'scale-y-100 text-inherit'
        : 'scale-y-0 text-transparent'
    "
  >
    <div
      class="mx-auto grid max-w-[1440px] grid-cols-[99.69px,auto] px-5 md:gap-8 xl:gap-12"
    >
      <div></div>
      <div class="bottom-0 z-10 flex gap-4 overflow-auto py-5">
        <HeaderMenuTreeBuilder :menu="menu">
          <template #item="{ menuItem }">
            <HeaderMenuDropDownItem :menu-item="menuItem" />
          </template>
        </HeaderMenuTreeBuilder>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useGlobalMenuStore } from '~/stores/headerMenu';
import { type MenuItem } from '../../../server/types/menu-types';

defineProps({
  menu: {
    type: Array as PropType<MenuItem[]>,
    required: true,
  },
  activeItem: {
    type: Object as PropType<MenuItem>,
    required: false,
    default: () => ({}),
  },
  hasVisibleChildren: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(['focusLeft']);
const headerMenu = useGlobalMenuStore();
await headerMenu.getMenu();

const navMenuDropdown = ref<HTMLElement | null>(null);

onMounted(() => {
  if (navMenuDropdown.value) {
    navMenuDropdown.value.addEventListener('focusout', (event) => {
      if (navMenuDropdown.value?.contains(event.relatedTarget as Node)) return;
      emit('focusLeft');
    });
  }
});

onUnmounted(() => {
  if (navMenuDropdown.value) {
    navMenuDropdown.value.removeEventListener('focusout', () => {});
  }
});

const { isNavMenuOpen } = storeToRefs(headerMenu);
</script>
