<template>
  <span
    v-if="isPrimaryNoLinkWithChildren(menuItem)"
    class="flex w-full items-center py-1.5 text-md font-normal text-supporting-black-87"
  >
    {{ menuItem.name }}
  </span>
  <NuxtLink
    v-if="isBanner(menuItem)"
    :to="menuItem.path"
    @click="closeAndScrollToTop()"
  >
    <img
      class="mt-4 aspect-[1.91/1] w-full rounded-lg"
      width="200"
      height="100"
      :src="`/ecom-assets/images/banners/${menuItem.banner}`"
      :alt="menuItem.name"
      loading="lazy"
    />
  </NuxtLink>
  <NuxtLink
    v-if="isPrimaryLinkWithChildren(menuItem)"
    :to="menuItem.path"
    class="link-in-menu flex w-full items-center gap-2.5 py-1.5 text-md font-normal text-supporting-black-87"
    :class="{
      [`${menuItem.classes}`]: menuItem.classes,
      hidden: !menuItem.isVisible,
      'hover:underline': menuItem?.path,
    }"
    :external="!!menuItem.newWindow"
    :target="menuItem.newWindow ? '_blank' : null"
    :rel="menuItem.isNoFollow ? 'nofollow' : null"
    @click="closeAndScrollToTop()"
  >
    {{ menuItem.name }}
    <StaticPictogram class="rotate-180" name="chevron" />
  </NuxtLink>
  <NuxtLink
    v-if="isPrimaryLinkWithoutChildren(menuItem)"
    :to="menuItem?.path"
    class="link-in-menu flex items-center gap-2.5 pb-9 pt-1.5 text-md text-supporting-black-87 antialiased"
    :class="{
      [`${menuItem.classes}`]: menuItem.classes,
      hidden: !menuItem.isVisible,
      'hover:underline': menuItem?.path,
    }"
    :external="!!menuItem.newWindow"
    :target="menuItem.newWindow ? '_blank' : null"
    :rel="menuItem.isNoFollow ? 'nofollow' : null"
    @click="closeAndScrollToTop()"
  >
    <StaticPictogram
      v-if="menuItem?.iconDesktop || menuItem?.icon"
      :name="menuItem?.iconDesktop || menuItem?.icon"
      size="lg"
      :class="
        menuItem?.icon === 'apotek' //Fix for Safari bug that blurs images with shadows
          ? 'shadow-[rgba(17,_15,_14,_0.16)_0px_0px_4px] ml-0.5'
          : ''
      "
    />
    {{ menuItem.name }}
    <DynamicPictogram
      v-if="menuItem.classes"
      name="chevron"
      class="rotate-180"
    />
    <StaticPictogram v-else name="chevron" class="rotate-180" />
  </NuxtLink>
  <NuxtLink
    v-if="isSecondaryLink(menuItem)"
    :to="menuItem?.path"
    class="link-in-menu flex py-[0.27rem] text-md text-supporting-black-54 antialiased hover:underline"
    :class="{
      [`${menuItem.classes}`]: menuItem.classes,
      hidden: !menuItem.isVisible,
      'hover:underline': menuItem?.path,
    }"
    :external="!!menuItem.newWindow"
    :target="menuItem.newWindow ? '_blank' : null"
    :rel="menuItem.isNoFollow ? 'nofollow' : null"
    @click="closeAndScrollToTop()"
  >
    {{ menuItem.name }}
  </NuxtLink>
</template>
<script lang="ts" setup>
import { useGlobalMenuStore } from '~/stores/headerMenu';
import { type MenuItem } from '../../../server/types/menu-types';

defineProps({
  menuItem: {
    type: Object as PropType<MenuItem>,
    required: false,
    default: () => ({}),
  },
});

const headerMenu = useGlobalMenuStore();
await headerMenu.getMenu();

const closeAndScrollToTop = () => {
  headerMenu.setIsNavMenuOpen(false);
  headerMenu.setActiveId(0);
  window.scrollTo(0, 0);
};

const isPrimaryNoLinkWithChildren = (menuItem: MenuItem) =>
  menuItem.children.length &&
  menuItem.level === 2 &&
  !menuItem.path &&
  !menuItem.banner;

const isPrimaryLinkWithChildren = (menuItem: MenuItem) =>
  menuItem.children.length &&
  menuItem.level === 2 &&
  menuItem.path &&
  !menuItem.banner;

const isPrimaryLinkWithoutChildren = (menuItem: MenuItem) =>
  !menuItem?.children?.length && menuItem.level === 2 && !menuItem.banner;

const isSecondaryLink = (menuItem: MenuItem) =>
  menuItem.level > 2 && !menuItem.banner;

const isBanner = (menuItem: MenuItem) => menuItem.banner;
</script>
