<template>
  <span v-if="false"></span>
</template>
<script lang="ts" setup>
/* This component is used to prevent scrolling on the body when the mobile menu is open. */

const scrollPosition = ref(0);

onMounted(() => {
  const body = document.querySelector('body');
  const html = document.querySelector('html');
  scrollPosition.value = window.pageYOffset;
  if (body === null || html === null) return;
  body.style.overflow = 'hidden';
  body.style.position = 'fixed';
  body.style.top = `-${scrollPosition}px`;
  body.style.width = '100%';
  html.style.height = '100vh';
});

onUnmounted(() => {
  const body = document.querySelector('body');
  const html = document.querySelector('html');
  if (body === null || html === null) return;
  body.style.removeProperty('overflow');
  body.style.removeProperty('position');
  body.style.removeProperty('top');
  body.style.removeProperty('width');
  html.style.removeProperty('height');
  window.scrollTo(0, scrollPosition.value);
});
</script>
