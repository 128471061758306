<template>
  <span v-if="false"></span>
</template>
<script lang="ts" setup>
import { useGlobalMenuStore } from '~/stores/headerMenu';

/* This component is used to reset the menu when the window is resized. */
const headerMenu = useGlobalMenuStore();

onMounted(() => {
  headerMenu.setActiveId(0);
});

onUnmounted(() => {
  headerMenu.setActiveId(0);
  headerMenu.setIsNavMenuOpen(false);
});
</script>
