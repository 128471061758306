<template>
  <div
    class="md:2/3 mx-auto mt-24 w-full p-4 text-center md:max-w-[350px] md:rounded-xl md:bg-white md:shadow-drop-lg"
  >
    <h2 class="mb-6 text-2xl">
      {{ $t('account.add_name_form.just_a_few_personal_details') }}
    </h2>
    <h3 class="mb-6 font-sans text-base text-black/54">
      {{ $t('account.add_name_form.get_to_know_you_more') }}
    </h3>
    <VeeForm class="mt-1 flex flex-1 flex-col" @submit="submit">
      <InputField
        v-model.trim="firstName"
        :label="$t('account.add_name_form.first_name')"
        name="first_name"
        type="first_name"
        autocomplete="first_name"
        rules="required"
      />
      <InputField
        v-model.trim="lastName"
        :label="$t('account.add_name_form.last_name')"
        name="lastName"
        type="lastName"
        autocomplete="lastName"
        rules="required"
      />
      <Button class="w-full" :loading="isSubmitting">
        {{ $t('account.add_name_form.continue') }}
      </Button>
    </VeeForm>
  </div>
</template>
<script setup lang="ts">
import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore();

const firstName = ref('');
const lastName = ref('');
const isSubmitting = ref(false);

const emit = defineEmits(['success']);

const submit = async () => {
  isSubmitting.value = true;
  await authStore.updateProfile(
    {
      firstName: firstName.value,
      lastName: lastName.value,
    },
    true // marks the user as 'Active' in Core API. Only use it when it's a user sign up
  );

  isSubmitting.value = false;
  emit('success', true);
};
</script>
