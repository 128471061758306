<template>
  <ClearCacheMeta v-if="!hasFooterMenu()" />
  <div :class="isShopRoute ? 'mt-16 lg:mt-20' : ''">
    <FooterPartnerLogos v-if="isShopRoute" />
    <footer class="bg-supporting-dark-1 pt-14 text-white lg:pt-16">
      <nav arial-label="Footer menu" class="mx-auto max-w-6xl px-6 pb-20">
        <div class="flex flex-col text-md lg:flex-row">
          <ul class="flex flex-col lg:w-[275px]">
            <li>
              <BrandLogo color-class="text-background-extra-light mb-4" />
            </li>
            <li
              v-for="entry in footerMainEntry.children"
              :key="entry.entityId"
              class="mt-4 flex flex-row items-center"
            >
              <DynamicPictogram
                v-if="entry['icon-desktop'] || entry.icon"
                :color="`${!entry.classes ? 'white' : ''}`"
                :class="`pr-2 hidden lg:inline ${entry.classes || ''}`"
                :name="`${entry['icon-desktop'] || entry.icon}`"
                size="lg"
              />
              <DynamicPictogram
                v-if="entry['icon-mobile'] || entry.icon"
                :color="`${!entry.classes ? 'white' : ''}`"
                :class="`pr-2 inline lg:hidden ${entry.classes || ''}`"
                :name="`${entry['icon-mobile'] || entry.icon}`"
                size="lg"
              />
              <NuxtLink
                :href="entry.path"
                :external="!!entry.newWindow"
                :target="entry.newWindow ? '_blank' : null"
                :class="`text-white ${entry.classes || ''}`"
                >{{ entry.name }}</NuxtLink
              >
            </li>
          </ul>
          <div class="flex flex-col lg:flex-row lg:justify-between">
            <div
              v-for="(entry, index) in footerOtherEntries"
              :key="entry.entityId"
              :class="`flex lg:w-[178px] ${index === footerOtherEntries.length - 1 ? '' : 'lg:mr-4'}`"
            >
              <ul :key="`${entry.entityId}-ul`">
                <FooterMenuEntry :entry="entry" />
              </ul>
            </div>
          </div>
        </div>

        <hr class="my-10 hidden border-white/30 lg:-mx-0 lg:block" />

        <FooterInfo class="mt-20 lg:mt-0" />
      </nav>
    </footer>
  </div>
</template>

<script setup lang="ts">
const route = useRoute();

const isShopRoute = computed(
  () => route?.name?.toString().includes('shop') || false
);

const { getFooterMenu, hasFooterMenu } = useGlobalFooterMenuStore();

const footerMenuData = await getFooterMenu();

const footerMainEntry = computed(
  () => footerMenuData?.filter((item) => item.name === 'Main')[0] || []
);

const footerOtherEntries = computed(
  () => footerMenuData?.filter((item) => item.name !== 'Main') || []
);
</script>
