<template>
  <ClearCacheMeta v-if="!headerMenu.hasMenu()" />
  <nav
    v-click-outside="onClickOutside"
    class="hidden h-full lg:flex"
    aria-label="Main Navigation"
  >
    <ul class="flex w-full md:gap-5 xl:gap-7">
      <template
        v-for="menuItem in enhanceMenuWithStyleData"
        :key="menuItem.entityId"
      >
        <li class="flex">
          <HeaderMenuItem :menu-item="menuItem" />
          <HeaderMenuDropDown
            v-if="$viewport.isGreaterThan('md')"
            :menu="menuItem.children"
            :has-visible-children="menuItem.hasVisibleChildren || false"
            @focus-left="handleFocusLeft"
          ></HeaderMenuDropDown>
        </li>
      </template>
    </ul>
  </nav>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useGlobalMenuStore } from '~/stores/headerMenu';
import { type MenuItem } from '../../../server/types/menu-types';
import {
  findMenuItemsByPath,
  findMenuItemById,
  tailwindSafeList,
} from './header-menu-helpers';

const { $viewport } = useNuxtApp();
const headerMenu = useGlobalMenuStore();
await headerMenu.getMenu();

const { menu, activeId, lastClickedTopLevelMenuItemId, isNavMenuOpen } =
  storeToRefs(headerMenu);

const onClickOutside = () => {
  if ($viewport.isGreaterThan('md') && isNavMenuOpen.value) {
    headerMenu.setIsNavMenuOpen(false);
    headerMenu.setActiveId(0);
  }
};

const route = useRoute();

const activeItemsFromUrl = computed(
  () => findMenuItemsByPath(menu.value, route.path) || []
);

const activeItemFromUrl = computed(
  () => activeItemsFromUrl.value[0] || undefined
);

const lastClickedTopLevelMenuItem = computed(() =>
  findMenuItemById(menu.value, lastClickedTopLevelMenuItemId.value)
);

function isAncestorOfActiveUrl(menuItem: MenuItem) {
  if (!activeItemFromUrl.value || menuItem.level !== 1) {
    return false;
  }
  // There are duplicates in the menu - we need to check which parent is active.
  if (
    activeItemsFromUrl.value.length > 1 &&
    lastClickedTopLevelMenuItem.value
  ) {
    return +lastClickedTopLevelMenuItem.value.entityId === +menuItem.entityId;
  }
  return activeItemFromUrl.value.parentIds?.[0] === menuItem.entityId;
}

const itemIsVisible = (item: MenuItem) =>
  item.level === 1 ||
  (isChildOfActive(item) && item.level !== 1) ||
  (+item.entityId === activeId.value && item.level !== 1);

const isChildOfActive = (item: MenuItem) =>
  item?.parentIds?.find((id: string) => activeId.value === parseInt(id, 10));

const unorderedListStyles: { [key: string]: string } = {
  1: 'w-full flex',
  2: 'columns-4 gap-x-12 w-full',
  3: 'pb-4 ',
};

const listItemStyles: { [key: string]: string } = {
  1: 'flex-col w-full break-inside-avoid',
  2: 'flex-col w-full break-inside-avoid',
  3: 'flex-col w-full break-inside-avoid',
};

const updateMenuTree = (item: MenuItem): MenuItem => {
  const childrenWithProperties = item.children?.map((child) =>
    updateMenuTree(child)
  );

  return {
    ...item,
    isVisible: itemIsVisible(item),
    isActiveUrl: activeItemFromUrl.value === item,
    isAncestorOfActiveUrl: isAncestorOfActiveUrl(item),
    hasVisibleChildren:
      childrenWithProperties?.some((child) => child.isVisible) || false,
    children: childrenWithProperties,
    unorderedListStyles: unorderedListStyles[item.level],
    listItemStyles: listItemStyles[item.level],
    customItemListStyles: tailwindSafeList(item.customItemListStyles),
    classes: tailwindSafeList(item.classes),
    isNoFollow: item?.classes?.includes('nofollow') || false,
  };
};

const enhanceMenuWithStyleData = computed(() =>
  menu.value.map((item) => updateMenuTree(item))
);

const handleFocusLeft = () => {
  if ($viewport.isGreaterThan('md') && isNavMenuOpen.value) {
    headerMenu.setIsNavMenuOpen(false);
    headerMenu.setActiveId(0);
  }
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'Escape' && isNavMenuOpen.value) {
    headerMenu.setIsNavMenuOpen(false);
    headerMenu.setActiveId(0);
  }
};

onMounted(() => {
  window.addEventListener('keydown', handleKeyDown);
});

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeyDown);
});
</script>
