<template>
  <NuxtLink
    v-if="menuItem.path"
    v-show="!menuItem.children.length"
    :to="menuItem.path"
    class="link-in-menu flex items-center justify-center px-3 py-1.5 text-md text-supporting-black-54"
    :class="{
      hidden: !menuItem.isVisible,
    }"
    :external="!!menuItem.newWindow"
    :target="menuItem.newWindow ? '_blank' : null"
    :rel="menuItem.isNoFollow ? 'nofollow' : null"
    @click="closeAndScrollToTop()"
  >
    {{ menuItem.name }}
  </NuxtLink>

  <button
    v-if="menuItem.children.length"
    class="relative flex w-full items-center whitespace-nowrap font-semibold text-supporting-black-87 after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:content-[''] hover:after:scale-y-100 hover:after:bg-supporting-dark-3"
    :class="linkClass()"
    :aria-expanded="
      isNavMenuOpen && +menuItem.entityId === activeId ? 'true' : 'false'
    "
    @click="toggleActiveId(+menuItem.entityId)"
  >
    <span class="mr-1 text-md font-normal antialiased">{{
      menuItem.name
    }}</span>
    <DynamicPictogram
      name="chevron"
      class="mt-0.5"
      :color="
        isNavMenuOpen && +menuItem.entityId === activeId
          ? 'rgb(231, 110, 71)'
          : 'rgba(17, 15, 14, 0.54)'
      "
      :class="
        isNavMenuOpen && +menuItem.entityId === activeId
          ? 'rotate-90'
          : '-rotate-90'
      "
    />
  </button>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useGlobalMenuStore } from '~/stores/headerMenu';
import { type MenuItem } from '../../../server/types/menu-types';

const props = defineProps({
  menuItem: {
    type: Object as PropType<MenuItem>,
    required: false,
    default: () => ({}),
  },
});

const headerMenu = useGlobalMenuStore();
await headerMenu.getMenu();

const { activeId, isNavMenuOpen } = storeToRefs(headerMenu);

const closeAndScrollToTop = () => {
  headerMenu.setIsNavMenuOpen(false);
  headerMenu.setActiveId(0);
  window.scrollTo(0, 0);
};

const toggleActiveId = (id: number) => {
  const alreadyActive = id === activeId.value && isNavMenuOpen.value;
  if (id === activeId.value) {
    headerMenu.setActiveId(0);
  } else {
    headerMenu.setActiveId(id);
    headerMenu.setLastClickedTopLevelMenuItemId(id);
  }
  headerMenu.setIsNavMenuOpen(!alreadyActive);
};

const linkClass = () => {
  /* User clicked the link to open dropdown */
  if (isNavMenuOpen.value && +props.menuItem.entityId === activeId.value) {
    return 'text-supporting-dark-3 after:scale-y-100 after:bg-supporting-dark-3';
  }
  /* Link har a child is in url */
  if (
    (!isNavMenuOpen.value && props.menuItem.isAncestorOfActiveUrl) ||
    (!isNavMenuOpen.value && props.menuItem.isActiveUrl)
  ) {
    return 'after:scale-y-100 after:bg-supporting-dark-3';
  }
  /* Default */
  return 'after:scale-y-0 after:bg-transparent';
};
</script>
