<template>
  <LockBodyScroll v-if="isNavMenuOpen" />
  <ResetMenu />
  <nav
    id="mobile-menu"
    aria-label="Main Navigation"
    :inert="!isNavMenuOpen"
    :aria-hidden="!isNavMenuOpen"
    class="fixed left-0 top-0 z-[200] h-dvh w-full origin-left overflow-auto rounded-t-xl border-t bg-background-extra-light transition-transform duration-300"
    :class="
      isNavMenuOpen ? 'translate-x-0' : '-translate-x-full text-transparent'
    "
  >
    <div class="bottom-0 mx-auto pb-12 antialiased">
      <div class="flex justify-between">
        <HeaderMenuMobileBackButton
          :parent-item="parentItem"
          :show="activeItem !== undefined"
        />
        <HeaderMenuMobileCloseButton />
      </div>

      <HeaderMenuTreeBuilder :menu="enhanceMenuWithStyleData">
        <template #item="{ menuItem }">
          <HeaderMenuMobileItem :menu-item="menuItem" />
        </template>
      </HeaderMenuTreeBuilder>
    </div>
  </nav>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useGlobalMenuStore } from '~/stores/headerMenu';
import {
  findMenuItemsByPath,
  findMenuItemById,
  findParentMenuItem,
  isActiveItem,
  isFirstChildOfActive,
  noActiveItemAndTopLevel,
  tailwindSafeList,
} from './header-menu-helpers';
import { type MenuItem } from '../../../server/types/menu-types';

const headerMenu = useGlobalMenuStore();
await headerMenu.getMenu();
const { isNavMenuOpen, menu, activeId, lastClickedTopLevelMenuItemId } =
  storeToRefs(headerMenu);
const route = useRoute();

const activeItemsFromUrl = computed(
  () => findMenuItemsByPath(menu.value, route.path) || []
);

const activeItemFromUrl = computed(
  () => activeItemsFromUrl.value[0] || undefined
);

const activeItemFromState = computed(() =>
  findMenuItemById(menu.value, +activeId.value)
);

const lastClickedTopLevelMenuItem = computed(() =>
  findMenuItemById(menu.value, lastClickedTopLevelMenuItemId.value)
);

const userHasNavigatedBackToMainMenu = computed(() => +activeId.value === 1);

const getParentIfNoChildren = (item: MenuItem) =>
  item?.children?.length > 0
    ? item
    : findParentMenuItem(menu.value, +item.entityId);

const activeItem = computed(() => {
  if (userHasNavigatedBackToMainMenu.value) {
    return undefined;
  }
  if (activeItemFromState.value) {
    return getParentIfNoChildren(activeItemFromState.value);
  }
  if (activeItemFromUrl.value) {
    if (
      // There are duplicates in the menu - we need to check which parent is active.
      activeItemsFromUrl.value.length > 1 &&
      lastClickedTopLevelMenuItem.value
    ) {
      return getParentIfNoChildren(lastClickedTopLevelMenuItem.value);
    }
    return getParentIfNoChildren(activeItemFromUrl.value);
  }
  return undefined;
});

const listItemStyles: { [key: string]: string } = {
  2: `relative after:absolute after:bottom-0 after:right-0 after:h-[1px] after:w-[calc(100%-1.25rem)] after:bg-black/6 after:content-['']`,
};

const updateMenuTree = (item: MenuItem): MenuItem => {
  const childrenWithProperties = item.children?.map((child) =>
    updateMenuTree(child)
  );

  return {
    ...item,
    isActive: isActiveItem(item, activeItem.value),
    isVisible:
      isFirstChildOfActive(item, activeItem.value) ||
      isActiveItem(item, activeItem.value) ||
      noActiveItemAndTopLevel(item, activeItem.value),
    hasVisibleChildren:
      childrenWithProperties?.some((child) => child.isVisible) || false,
    children: childrenWithProperties,
    unorderedListStyles: listItemStyles[item.level],
    customItemListStyles: tailwindSafeList(item.customItemListStyles) || '',
    classes: tailwindSafeList(item.classes) || '',
    isNoFollow: item?.classes?.includes('nofollow') || false,
  };
};

const enhanceMenuWithStyleData = computed(() =>
  menu.value.map((item) => updateMenuTree(item))
);

const parentItem = computed(() => {
  if (activeItem.value) {
    return findParentMenuItem(menu.value, +activeItem.value.entityId);
  }
  return undefined;
});
</script>
