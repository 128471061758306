<template>
  <button
    class="relative z-60 flex flex-col items-center p-5 pr-6 focus-visible:ring"
    aria-label="Close navigation menu"
    @click="toggleNavMenu()"
  >
    <DynamicPictogram name="times" size="ml" />
  </button>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useGlobalMenuStore } from '~/stores/headerMenu';

const headerMenu = useGlobalMenuStore();
await headerMenu.getMenu();
const { isNavMenuOpen } = storeToRefs(headerMenu);

const toggleNavMenu = () => {
  if (isNavMenuOpen.value) {
    headerMenu.setIsNavMenuOpen(false);
    headerMenu.setActiveId(0);
  } else {
    headerMenu.setIsNavMenuOpen(true);
  }
};
</script>
