<template>
  <div>
    <transition name="fade" @after-leave="toggleCartRecommendations(false)">
      <div
        v-show="isSidebarOpen"
        class="fixed inset-0 z-40 size-full bg-gray-900/50"
        @click.self="toggleSidebar(false)"
      />
    </transition>

    <transition name="slide-right">
      <div
        v-show="isSidebarOpen"
        class="fixed right-0 top-0 z-50 ml-auto flex size-full flex-col bg-background-extra-light md:w-[31rem]"
      >
        <SidebarContentCartRecommendations v-if="showCartRecommendations" />
        <SidebarContentCart v-else />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useSidebarStore } from '~/stores/sidebar';

const sidebarStore = useSidebarStore();

const isSidebarOpen = computed(() => sidebarStore.isSidebarOpen);
const showCartRecommendations = computed(
  () => sidebarStore.showCartRecommendations
);

const toggleSidebar = (shouldShow: boolean) =>
  sidebarStore.toggleSidebar(shouldShow);
const toggleCartRecommendations = (shouldShow: boolean) =>
  sidebarStore.toggleCartRecommendations(shouldShow);
</script>
