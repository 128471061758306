import { defineStore } from 'pinia';

export const useGlobalMenuStore = defineStore('headerMenu', () => {
  const menu = ref([]);
  const activeId = ref(0);
  const isNavMenuOpen = ref(false);
  const lastClickedTopLevelMenuItemId = ref(0);
  let fetch: Promise<{ data: any }>;

  async function getMenu() {
    if (menu.value.length) {
      return menu.value;
    }

    if (fetch === undefined) {
      fetch = useFetch(`/ecom-api/editorial/get-header-menu`, {
        method: 'POST',
        body: JSON.stringify({
          regionCode: useRegionCode(),
        }),
      });
    }

    const { data: menuResponse } = await fetch;

    menu.value = menuResponse?.value?.menu || [];

    return menu.value;
  }

  function hasMenu() {
    return menu.value.length > 0;
  }

  const setActiveId = (newActiveid: number) => {
    activeId.value = newActiveid;
  };

  const setLastClickedTopLevelMenuItemId = (entityId: number) => {
    lastClickedTopLevelMenuItemId.value = entityId;
  };

  const setIsNavMenuOpen = (open: boolean) => {
    isNavMenuOpen.value = open;
  };

  return {
    menu,
    getMenu,
    hasMenu,
    activeId,
    setActiveId,
    isNavMenuOpen,
    setIsNavMenuOpen,
    lastClickedTopLevelMenuItemId,
    setLastClickedTopLevelMenuItemId,
  };
});
