<template>
  <button
    v-if="show"
    class="flex items-center p-5 text-supporting-black-54"
    @click="goBackToParent()"
  >
    <DynamicPictogram name="arrow" size="ml" class="mr-2" />
    {{ parentItem?.name || $t('back_home') }}
  </button>
  <div v-else></div>
</template>
<script lang="ts" setup>
import { useGlobalMenuStore } from '~/stores/headerMenu';
import { type MenuItem } from '../../../server/types/menu-types';

const headerMenu = useGlobalMenuStore();

const props = defineProps({
  parentItem: {
    type: Object as PropType<MenuItem>,
    required: false,
    default: () => ({}),
  },
  show: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const goBackToParent = () => {
  const parentId = props.parentItem?.entityId;
  if (parentId !== undefined) {
    headerMenu.setActiveId(parseInt(parentId, 10));
  } else {
    headerMenu.setActiveId(1);
  }
};
</script>
