<template>
  <div class="w-full">
    <div v-click-outside="onClickOutside" class="w-full">
      <button
        class="flex w-full cursor-pointer flex-nowrap items-center rounded px-3 py-2 hover:bg-[#ffffff29]"
        :aria-expanded="isOpen"
        aria-label="Toggle language selector"
        @click="isOpen = !isOpen"
      >
        <Flag
          :code="currentLocale.code"
          :name="currentLocale.name"
          :lazy="true"
          class="mr-3 size-10"
        />
        {{ currentLocale.name }}
      </button>
      <nav
        class="flex-col gap-1 bg-transparent"
        :class="isOpen ? 'flex' : 'hidden'"
        aria-label="Language selector"
      >
        <ul>
          <li
            v-for="filteredLocale in filteredLocales"
            :key="filteredLocale.code"
          >
            <a
              class="md:w-a flex w-full items-center rounded px-3 py-2 hover:bg-[#ffffff29]"
              :href="`/${filteredLocale.code}`"
              @click="clearCart"
            >
              <Flag
                :code="filteredLocale.code"
                :name="filteredLocale.name"
                :lazy="true"
                class="mr-3 size-10"
              />
              {{ filteredLocale.name }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { shopCountryConfigs } from '#root/shared/config/country-config';
import { useCartStore } from '~/stores/cart';

const onClickOutside = () => {
  isOpen.value = false;
};

const cartStore = useCartStore();
const { locale, locales } = useI18n();

const isOpen = ref(false);

const filteredLocales = computed(() =>
  availableLocales.value.filter((l) => l.code !== currentLocale.value?.code)
);

const { brand } = useRuntimeConfig().public;

const availableLocales = computed(() =>
  locales.value.filter(
    (l) =>
      !shopCountryConfigs[brand as ShopBrand][l.code as LocaleTerritory]?.hidden
  )
);

const currentLocale = ref(
  (availableLocales.value as Array<Locale>).find((l) => l.code === locale.value)
);

const clearCart = () => cartStore.clearCart();

watch(
  locale,
  (newLocale) => {
    const newCurrentLocale = (availableLocales.value as Array<Locale>).find(
      (l) => l.code === newLocale
    );
    if (newCurrentLocale) {
      currentLocale.value = newCurrentLocale;
    }
  },
  { immediate: true }
);
</script>
